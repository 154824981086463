import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { Col, Form, Row } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { MouseEvent, useContext, useEffect, useState } from 'react';
import {
	FileDocument,
	Images,
	Product,
	DataFragmentVM,
	DATAFRAGMENT_TYPE,
} from '../../../models/models';
import { DatalakeContext } from '../../../contexts/DatalakeContext';
import Spinner from '../../../components/bootstrap/Spinner';
import FragmentImagePicker from './fragmentImagePicker';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import { ModalFooter } from '../../../components/bootstrap/Modal';
import datalakeModal from './datalakeModal';
import { Modals } from '../../../components/Modals';
import { useModal } from '@ebay/nice-modal-react';
import Select from '../../../components/bootstrap/forms/Select';
import { isValidUrl, uniqueId, urlToFileObject } from '../../../util/util';
import DocumentPicker from './documentPicker';
import FileInput from '../../../components/FileInput';
import Swal from 'sweetalert2';
import useTranscribe from '../../../hooks/useTranscribe';
import Icon from '../../../components/icon/Icon';
import { ClientContext } from '../../../contexts/ClientContext';
import { WorkflowContext } from '../../../contexts/WorkflowContext';
import useImageUpload from '../../../helpers/useImageUpload';
import AudioTranscriber from './AudioTranscribe';
import { DocumentPickerFormComponent } from './DocumetnPickerFormComponent';

export interface FragmentFormPredefinedType {
	datalakeType: DATAFRAGMENT_TYPE;
	closeModal: () => void;
	setValueToForm: (e: string) => void;
	hideCloseButton?: boolean;
}

const FragmentForm = ({ predefinedData }: { predefinedData?: FragmentFormPredefinedType }) => {
	const {
		save,
		fragment: product,
		fragmentId: productId,
		selectedFragmentType,
	} = useContext(DatalakeContext);
	const { uploadFileToBlobStorage } = useImageUpload();
	const [err, setErr] = useState<string>();
	const [name, setName] = useState<string>('');
	const [level1, setLevel1] = useState('');
	const [level2, setLevel2] = useState('');
	const [level3, setLevel3] = useState('');
	const [description, setDescription] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [images, setImages] = useState<Images[]>([]);
	const [audios, setAudios] = useState<Images[]>([]);
	const [videos, setVideos] = useState<Images[]>([]);
	const [documents, setDocuments] = useState<FileDocument[]>([]);
	const [uploadingWeb, setUploadingWeb] = useState(false);
	const { getWebToText, getSpeechToText } = useTranscribe();
	const [usingFileInput, setUsingFileInput] = useState(false);
	const [fileInputType, setFileInputType] = useState('');
	const [uploadingUrl, setUploadingUrl] = useState(false);
	const [urlInput, setUrlInput] = useState('');
	const [uploadingSpeech, setUploadingSpeech] = useState(false);
	const { activeClientId, activeClient, missionHierarchySettings } = useContext(ClientContext);
	const { workflow, workflowTemplate: wft } = useContext(WorkflowContext);
	const [selectedType, setSelectedType] = useState<DATAFRAGMENT_TYPE>(
		predefinedData?.datalakeType ?? 'product',
	);

	const modal = useModal(Modals.DATALAKE);
	const types = [
		{ value: 'product', label: 'Product' },
		{ value: 'company', label: 'Company' },
		{ value: 'image', label: 'Image' },
		{ value: 'user', label: 'User' },
		{ value: 'document', label: 'Document' },
		{ value: 'lead', label: 'Lead' },
		{ value: 'content', label: 'Content' },
		{ value: 'material', label: 'Material' },
		{ value: 'object', label: 'Object' },
	];

	const onTypeSelect = (e: any) => {
		setSelectedType(e?.target?.value);
	};
	useEffect(() => {
		if (product) {
			setName(product?.name);
			setDescription(product?.text);
			setImages(product?.images ?? []);
			setAudios(product?.audios ?? []);
			setVideos(product?.videos ?? []);
			setSelectedType(product?.type);
			setLevel1(product?.hierarchy?.level1 ?? '');
			setLevel2(product?.hierarchy?.level2 ?? '');
			setLevel3(product?.hierarchy?.level3 ?? '');
		} else {
			setName('');
			setDescription('');
			setImages([]);
			setAudios([]);
			setVideos([]);
			setSelectedType(predefinedData?.datalakeType ?? selectedFragmentType ?? 'product');
			setLevel1(missionHierarchySettings?.level1?.[0] ?? '');
			setLevel2(missionHierarchySettings?.level2?.[0] ?? '');
			setLevel3(missionHierarchySettings?.level3?.[0] ?? '');
		}
	}, [product]);
	const handleProductSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
		e?.preventDefault();
		setLoading(true);
		const productVM: DataFragmentVM = {
			folder: ['/', selectedType],
			name: name,
			text: description,
			images: images,
			audios: audios,
			videos: videos,
			documents: documents,
			type: "document",
			userId: '',
			hierarchy: {
				level1,
				level2,
				level3,
				level4: '',
			},
		};
		const newId = await save(productVM);
		setLoading(false);
		modal.hide();
		if (!!predefinedData?.datalakeType) {
			predefinedData.setValueToForm(newId);
			predefinedData.closeModal();
		}
	};
	const handleButtonClick = () => {
		if (window.confirm('Are you sure you want to close this tab?')) {
			modal.hide();
			if (!!predefinedData?.datalakeType) {
				predefinedData.closeModal();
			}
		}
	};
	const [isHovered, setIsHovered] = useState(false);
	const handleMouseEnter = () => {
		setIsHovered(true);
	};
	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const convertWebToText = async () => {
		setUploadingWeb(true);
		const urlMayBe = description?.replace('<p>', '')?.replace('</p>', '') ?? '';
		const result = await getWebToText({ url: urlMayBe, referenceId: selectedType });
		setDescription(result?.htmlTextArray);
		setUploadingWeb(false);
	};
	const setDataUrl = (url: string, text: string, type: string) => {
		setDescription(`${description} \n ${text === url ? '' : text}`);
		console.log('value changed', url, type);
		setUploadingUrl(false);
		setUsingFileInput(false);
		setFileInputType('');
		//dataChanged(field, `${!!value ? `${value} \n` : ''} ${text === url ? '' : text}`);
	};
	const handleSubmit = async (url: string) => {
		setUploadingUrl(true);
		if (!!isValidUrl(url)) {
			const result = await getWebToText({
				url: url,
				referenceId: workflow?.clientId ?? 'clientId',
			});
			if (!!result?.htmlTextArray) {
				setDataUrl(url, result?.htmlTextArray, 'web');
			} else {
				Swal.fire({
					title: `Sorry!, Something went wrong`,
					text: `Can't able to process this Url please try some other Urls.`,
					allowOutsideClick: true,
					confirmButtonText: 'Okay',
					cancelButtonColor: '#38cab3',
					confirmButtonColor: 'rgba(0, 56, 255, 1)',
				});
			}
		} else {
			Swal.fire({
				title: 'Error!',
				text: 'Invalid Url.',
				allowOutsideClick: true,
				confirmButtonText: 'Okay',
				cancelButtonColor: '#38cab3',
				confirmButtonColor: 'rgba(0, 56, 255, 1)',
			});
			setUploadingUrl(false);
			setUsingFileInput(false);
			setFileInputType('');
		}
	};

	const uploadAudio = async (blob: Blob) => {
		setUploadingSpeech(true);
		try {
			const downloadUrl = await uploadFileToBlobStorage({ file: blob });
			if (downloadUrl) {
				const result = await getSpeechToText(downloadUrl.url, workflow?.clientId ?? 'clientId');
				setDataUrl(downloadUrl.url, result, 'speech');
			}
		} catch (err) {
			console.log({ err });
		}
		setUploadingSpeech(false);
	};
	return (
		<Card className='position-relative'>
			<CardHeader>
				<CardLabel icon='Inventory 2' className='mb-6' iconColor='info'>
					<CardTitle className='capitalize'>{selectedType} Details</CardTitle>
				</CardLabel>
				<div className='d-flex justify-content-end' style={{ paddingRight: '15px' }}>
					<div
						className='d-flex justify-content-end py-4'
						style={{ marginRight: '25px' }}>
						<button
							className='btn btn-blue'
							type='submit'
							disabled={!name || !level1 || !level2 || !level3 || !selectedType}
							onClick={(e) => handleProductSubmit(e)}>
							{loading ? <Spinner /> : 'Save'}
						</button>
					</div>

					{!predefinedData?.hideCloseButton && (
						<button
							style={{
								fontSize: '20px',
								color: isHovered ? 'black' : 'grey',
								background: 'none',
								border: 'none',
								fontWeight: isHovered ? 'bold' : '',
							}}
							onClick={handleButtonClick}
							// onMouseEnter={handleMouseEnter}
							// onMouseLeave={handleMouseLeave}
						>
							<Icon icon='Close' size={'2x'} />
						</button>
					)}
				</div>
			</CardHeader>
			<CardBody>
				<Form className='mb-5'>
					<FormGroup className='control-group form-group mb-3'>
						<Form.Label>Select Type *</Form.Label>
						<div className='SlectBox text-black'>
							<Select
								placeholder='Select Industry'
								list={types}
								value={selectedType}
								defaultValue={selectedType}
								onChange={(e) => onTypeSelect(e)}
								ariaLabel='formselect'
							/>
						</div>
					</FormGroup>
					<FormGroup className='control-group form-group mb-3'>
						<Form.Label>{missionHierarchySettings?.levelTitles?.[0]} *</Form.Label>
						<div className='SlectBox text-black'>
							<Select
								placeholder={`Select ${missionHierarchySettings?.levelTitles?.[0]}`}
								list={(missionHierarchySettings?.level1 ?? [])?.map((item) => ({
									value: item,
									label: item,
								}))}
								value={level1}
								defaultValue={level1}
								onChange={(e: any) => setLevel1(e?.target?.value)}
								ariaLabel='formselect'
							/>
						</div>
					</FormGroup>
					<FormGroup className='control-group form-group mb-3'>
						<Form.Label>{missionHierarchySettings?.levelTitles?.[1]} *</Form.Label>
						<div className='SlectBox text-black'>
							<Select
								placeholder={`Select ${missionHierarchySettings?.levelTitles?.[1]}`}
								list={(missionHierarchySettings?.level2 ?? [])?.map((item) => ({
									value: item,
									label: item,
								}))}
								value={level2}
								defaultValue={level2}
								onChange={(e: any) => setLevel2(e?.target?.value)}
								ariaLabel='formselect'
							/>
						</div>
					</FormGroup>
					<FormGroup className='control-group form-group mb-3'>
						<Form.Label>{missionHierarchySettings?.levelTitles?.[2]} *</Form.Label>
						<div className='SlectBox text-black'>
							<Select
								placeholder={`Select ${missionHierarchySettings?.levelTitles?.[2]}`}
								list={(missionHierarchySettings?.level3 ?? [])?.map((item) => ({
									value: item,
									label: item,
								}))}
								value={level3}
								defaultValue={level3}
								onChange={(e: any) => setLevel3(e?.target?.value)}
								ariaLabel='formselect'
							/>
						</div>
					</FormGroup>
					<FormGroup
						label={`Your ${selectedType} ${
							selectedType == 'content' ? 'title' : 'name'
						} *`}
						className='mb-3 capitalize'>
						<Form.Control
							type='text'
							value={name}
							onChange={(e) => setName(e?.target?.value)}
						/>
					</FormGroup>

					<FormGroup className='mb-3'>
						<Form.Label>
							{/* {`Describe your ${selectedType} in a few words`} */}
							Summary
						</Form.Label>
						<div className='d-flex mx-2'>
							<div className='col-12 position-relative'>
								{!!usingFileInput && !!fileInputType ? (
									<div>
										{fileInputType == 'websiteScrap' ? (
											<div className='col-11'>
												<div className='mt-2'>
													<h5 className='modal-title'>Enter URL</h5>
												</div>
												<div className='mt-3 mb-3'>
													<input
														type='text'
														className='form-control'
														value={urlInput}
														style={{
															lineHeight: '2rem',
														}}
														onChange={(e) =>
															setUrlInput(e.target.value)
														}
													/>
												</div>
												<div className='my-4 modal-footer'>
													<button
														type='button'
														className='btn btn-Secondary'
														style={{
															backgroundColor: ' #334151',
															color: 'white',
														}}
														onClick={() => {
															setUsingFileInput(false);
															setFileInputType('');
														}}>
														Cancel
													</button>
													<button
														type='button'
														className='btn btn-blue btn py-2 px-4'
														style={{ marginLeft: '20px' }}
														disabled={uploadingUrl}
														onClick={() => {
															handleSubmit(urlInput);
														}}>
														{uploadingUrl ? (
															<span>
																Processing{' '}
																<Spinner
																	className='spin-btn'
																	isSmall
																	inButton
																	color={'dark'}
																/>
															</span>
														) : (
															<span>Submit</span>
														)}
													</button>
												</div>
											</div>
										) : fileInputType == 'audioScrap' ? (
											uploadingSpeech ? (
												<div className='d-flex justify-content-center py-5 my-4'>
													<Spinner
														className='spin-btn'
														isSmall
														size={60}
														inButton
														color={'dark'}
													/>
												</div>
											) : (
												<AudioTranscriber uploadAudio={uploadAudio} />
											)
										) : fileInputType === 'pdfScrap' ? (
											<DocumentPickerFormComponent
												setValue={setDescription}
												value={description}
												setFileInputType={setFileInputType}
												setUsingFileInput={setUsingFileInput}
												dataChanged={() => {}}
											/>
										) : (
											<div></div>
										)}
									</div>
								) : (
									<ReactQuill
										className='product-description'
										style={{
											height: 160,
											marginBottom: '10px',
											paddingRight: '50px',
										}}
										value={description}
										onChange={setDescription}
									/>
								)}
							</div>
							<div className='' style={{ marginTop: '25px' }}>
								<FileInput
									setFileUrl={(url, text, type) => {
										if (type === 'pdf') {
											setDescription(
												`${description} \n ${text === url ? '' : text}`,
											);
											setDocuments((prev) => [...prev, { type: 'pdf', url }]);
										} else if (type === 'speech') {
											setDescription(
												`${description} \n ${text === url ? '' : text}`,
											);
										} else if (type === 'web') {
											setDescription(
												`${description} \n ${text === url ? '' : text}`,
											);
										}
									}}
									convertWebToText={convertWebToText}
									setUsingFileInput={setUsingFileInput}
									setFileInputType={setFileInputType}
									fromFormComponent={true}
									uploadingWeb={uploadingWeb}
									selectedFileType={fileInputType}
								/>
							</div>
						</div>
					</FormGroup>
				</Form>
				{/* {selectedType == 'document' ? (
					<DocumentPicker
						productName={name}
						selectedType={selectedType}
						documents={documents}
						setDocuments={setDocuments}
						onDocumentUpload={(url, text) => {
							setDescription(`${description} \n ${text === url ? '' : text}`);
						}}
					/>
				) : ( */}
					<>
						<FragmentImagePicker
							fragmentName={name}
							selectedType={selectedType}
							images={images}
							setImages={setImages}
						/>
						<DocumentPicker
							productName={name}
							selectedType={selectedType}
							documents={documents}
							setDocuments={setDocuments}
							onDocumentUpload={(url, text) => {
								setDescription(`${description} \n ${text === url ? '' : text}`);
							}}
						/>
					</>
				{/* )} */}
			</CardBody>
			{/* <CardFooter className='position-relative'> */}
			{/* <ModalFooter className='w-100'>
				<div className='d-flex justify-content-end py-4'>
					<button
						className='btn btn-blue'
						type='submit'
						onClick={(e) => handleProductSubmit(e)}>
						{loading ? <Spinner /> : 'Save'}
					</button>
				</div>
			</ModalFooter> */}
			{/* </CardFooter> */}
		</Card>
	);
};

export default FragmentForm;
