export enum Modals {
	PRODUCT = 'Product',
	PRODUCT_IMAGE_PICKER = 'ProductImagePicker',
	PRODUCT_SUCCESS_STORIES = 'ProductSuccessStories',
	PRODUCT_TESTIMONIALS = 'ProductTestimonials',
	PRODUCT_EVENT_ACTIVITIES = 'ProductEventAndActivities',
	PRODUCT_NEWS_EXOGENOUS = 'ProductNewsAndExogenous',
	PRODUCT_COMPLIANCE_CERTIFICATIONS = 'ProductComplianceAndCertifications',
	PRODUCT_FEATURES = 'ProductFeatures',
	PRODUCT_BENEFITS = 'ProductBenefits',
	PRODUCT_IMAGE_GRID = 'ProductImageGrid',

	CAMPAIGN = 'Campaign',
	CAMPAIGN_ADD_CUSTOMER_IMAGE = 'CampaignAddCustomerImage',
	CAMPAIGN_IMAGE_UPLOAD = 'CampaignImageUpload',
	CAMPAIGN_IMAGE_GRID = 'CampaignImageGrid',

	WORKFLOW = 'Workflow',
	COLLABORATION_REQUEST = 'COLLABORATION_REQUEST',
	COMPANY = 'Company',
	COMPANY_COMPETITOR_INFO = 'CompanyCompetitorInfo',
	COMPANY_SUCCESS_STORIES = 'CompanySuccessStories',
	PROCESSING = 'Processing',

	SEARCH = 'Search',
	FeedbackToMail = 'FeedbackToMail',
	ADD_DATALAKE_TO_FORM = 'AddDatalakeToForm',

	PROFILE_SOCIAL_MEDIA = 'ProfileSocialMedia',

	ALERT = 'Alert',
	WARNING = 'Warning',

	DATALAKE = 'DataLake',
	MISSIONBUILDER = 'MissionBuilder',
	CREATESTEPMISSIONBUILDER = 'CreatestepMissionBuilder',

	MARKETINGDASHBOARD = 'MarketingDashboard',
	OUTPUTINFO = 'OutputInfo',
	SUGGESTIONMODAL = 'SuggestionModal',

	ASSET_DETAILS = 'Asset Detail',
}
