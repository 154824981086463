import React, { useContext } from 'react';
import ThemeContext from '../../../contexts/ThemeContext';
import Icon from '../../../components/icon/Icon';
import { useLocation } from 'react-router-dom';

function ChatSpeedDial() {
	const { assetChatCanvasStatus, setAssetChatCanvasStatus } = useContext(ThemeContext);
	const { pathname } = useLocation();
	return (
		<>
			{!pathname?.includes('disseminate') ? (
				<div className='fixed-menu  position-fixed' style={{ right: 20, bottom: 20 }}>
					<div
						className='main-menu hide cursor-pointer'
						onClick={() => setAssetChatCanvasStatus(!assetChatCanvasStatus)}>
						{assetChatCanvasStatus ? (
							<Icon icon='Close' size={'2x'} />
						) : (
							<Icon icon='Chat' size={'2x'} />
						)}
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
}

export default ChatSpeedDial;
