import React, { useContext, useRef, useState } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { isValidUrl, urlToFileObject } from '../util/util';
import { UserContext } from '../contexts/UserContext';
import useTranscribe from '../hooks/useTranscribe';
import Button from './bootstrap/Button';
import Spinner from './bootstrap/Spinner';
import { WorkflowContext } from '../contexts/WorkflowContext';
import { ClientContext } from '../contexts/ClientContext';
import Swal from 'sweetalert2';
import useDarkMode from '../hooks/useDarkMode';
import useImageUpload from '../helpers/useImageUpload';
import { Tooltip } from 'react-tooltip';

const FileInput = ({
	setFileUrl,
	convertWebToText,
	uploadingWeb,
	setUsingFileInput,
	setFileInputType,
	fromFormComponent,
	selectedFileType,
}: {
	setFileUrl: (url: string, text: string, type: 'speech' | 'pdf' | 'web') => void;
	uploadingWeb: boolean;
	fromFormComponent: boolean;
	convertWebToText: () => void;
	setUsingFileInput: React.Dispatch<React.SetStateAction<boolean>>;
	setFileInputType: React.Dispatch<React.SetStateAction<string>>;
	selectedFileType?: string;
}) => {
	const { activeUser } = useContext(UserContext);
	const [uploadingSpeech, setUploadingSpeech] = useState(false);
	const [openUrlModal, setOpenUrlModal] = useState(false);
	const { uploadFileToBlobStorage } = useImageUpload();
	const [uploadingUrl, setUploadingUrl] = useState(false);
	const [urlInput, setUrlInput] = useState('');
	const { workflow } = useContext(WorkflowContext);
	const recorderControls = useAudioRecorder(
		{
			noiseSuppression: true,
			echoCancellation: true,
		},
		(err) => console.table(err),
	);
	const { getSpeechToText } = useTranscribe();

	const handleRecordingComplete = async (blob: Blob) => {
		await uploadAudio(blob);
	};

	const uploadAudio = async (blob: Blob) => {
		setUploadingSpeech(true);
		try {
			const downloadUrl = await uploadFileToBlobStorage({ file: blob });
			if (!!downloadUrl) {
				const audioFile = await urlToFileObject(
					downloadUrl.url,
					`${activeUser?.name?.toLowerCase().replace(/\s/g, '_')}.webm`,
				);
				const result = await getSpeechToText(
					downloadUrl.url,
					workflow?.clientId ?? 'clientId',
				);
				setFileUrl(downloadUrl.url, result, 'speech');
			}
		} catch (err) {
			console.log({ err });
		}
		setUploadingSpeech(false);
	};

	const [openMenu, setOpenMenu] = useState(false);

	const { getWebToText } = useTranscribe();
	const { darkModeStatus } = useDarkMode();

	const handleSubmit = async (url: string) => {
		setUploadingUrl(true);
		if (!!isValidUrl(url)) {
			const result = await getWebToText({
				url: url,
				referenceId: workflow?.clientId ?? 'clientId',
			});
			setFileUrl(url, result?.htmlTextArray, 'web');
			// setValue(result?.htmlTextArray);
			console.log('url result', result.htmlTextArray);
		} else {
			Swal.fire({
				title: 'Error!',
				text: 'Invalid Url.',
				allowOutsideClick: true,
				confirmButtonText: 'Okay',
				cancelButtonColor: '#38cab3',
				confirmButtonColor: 'rgba(0, 56, 255, 1)',
			});
		}
		setUploadingUrl(false);
		setOpenUrlModal(false);
	};

	return (
		<>
			<div>
				<div
					className='modal'
					role='dialog'
					style={{ display: openUrlModal ? 'block' : 'none' }}>
					<div className='modal-dialog' role='document'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5 className='modal-title'>Enter URL</h5>
								<button
									type='button'
									className='close'
									onClick={() => {
										setOpenUrlModal(false);
									}}>
									<span>&times;</span>
								</button>
							</div>
							<div className='modal-body'>
								<input
									type='text'
									className='form-control'
									value={urlInput}
									onChange={(e) => setUrlInput(e.target.value)}
								/>
							</div>
							<div className='modal-footer'>
								<button
									type='button'
									className='btn btn-Secondary'
									onClick={() => {
										setOpenUrlModal(false);
									}}>
									Cancel
								</button>
								<button
									type='button'
									className='btn btn-blue btn py-2 px-4'
									style={{ marginLeft: '20px' }}
									disabled={uploadingUrl}
									onClick={() => {
										handleSubmit(urlInput);
									}}>
									{uploadingUrl ? (
										<span>
											Saving <Spinner isSmall inButton color={'light'} />
										</span>
									) : (
										<span>Submit</span>
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='d-flex mt-4 mx-4 position-relative input-container'>
				<div>
					<div
						className={`input-option-btn-wrapper
							show position-absolute h-auto`}
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}>
						{fromFormComponent ? (
							<>
								<Button
									icon='KeyboardVoice'
									data-tooltip-id='voice-input'
									data-tooltip-content='Voice input'
									onClick={(e: any) => {
										e?.preventDefault();
										if (selectedFileType == 'audioScrap') {
											setUsingFileInput(false);
											setFileInputType('');
										} else {
											setUsingFileInput(true);
											setFileInputType('audioScrap');
										}
									}}
									style={{
										border: 'none',
										backgroundColor: 'white',
										padding: 12,
									}}
									className={`my-2 input-option-btn ${
										darkModeStatus ? 'shadow-sm' : ''
									}`}></Button>
								<Tooltip id='voice-input' />
							</>
						) : (
							<div className='my-2'>
								<AudioRecorder
									onRecordingComplete={handleRecordingComplete}
									recorderControls={recorderControls}
									audioTrackConstraints={{
										noiseSuppression: true,
										echoCancellation: true,
									}}
									onNotAllowedOrFound={(err) => console.table(err)}
									downloadOnSavePress={false}
									downloadFileExtension='webm'
									mediaRecorderOptions={{
										audioBitsPerSecond: 128000,
									}}
									showVisualizer={true}
								/>
							</div>
						)}
						{!uploadingSpeech && (
							<>
								<Button
									icon='AttachFile'
									data-tooltip-id='attach-document'
									data-tooltip-content='Attach file'
									onClick={(e: any) => {
										if (selectedFileType == 'pdfScrap') {
											setUsingFileInput(false);
											setFileInputType('');
										} else {
											setUsingFileInput(true);
											setFileInputType('pdfScrap');
										}
									}}
									style={{
										border: 'none',
										backgroundColor: 'white',
										padding: 12,
									}}
									className={`my-2 input-option-btn ${
										darkModeStatus ? 'shadow-sm' : ''
									}`}></Button>
								<Tooltip id='attach-document' />
							</>
						)}
						{!uploadingWeb && !uploadingSpeech && (
							<>
								<Button
									icon='Language'
									data-tooltip-id='website-scrap'
									data-tooltip-content='Website scrap'
									onClick={() => {
										if (fromFormComponent) {
											if (selectedFileType == 'websiteScrap') {
												setUsingFileInput(false);
												setFileInputType('');
											} else {
												setUsingFileInput(true);
												setFileInputType('websiteScrap');
											}
										} else {
											setOpenUrlModal(true);
										}
									}}
									isDisable={uploadingWeb}
									style={{
										border: 'none',
										backgroundColor: 'white',
										padding: 12,
									}}
									className={`my-2 input-option-btn ${
										darkModeStatus ? 'shadow-sm' : ''
									}`}></Button>
								<Tooltip id='website-scrap' />
							</>
						)}
						{(uploadingSpeech || uploadingWeb) && (
							<Spinner className='my-2 input-option-btn' />
						)}
					</div>
				</div>
				{/* <Button
					icon={openMenu ? 'Close' : 'Menu'}
					onClick={() => setOpenMenu(!openMenu)}
					style={{ border: 'none', backgroundColor: 'white' }}
					className='my-2 input-option-btn option-toggle-btn'></Button> */}
			</div>
		</>
	);
};

export default FileInput;
